import {Routes} from '@angular/router';
import { HomeComponent } from './home/home.component';
import {storeNamesResolver} from "./_resolvers/store-names.resolver";
import {authGuard} from "./_guards/auth.guard";

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'qrph-reports',
        loadChildren: () => import('./qrph-reports/qrph-reports-routing.module'),
        resolve: {storeNames: storeNamesResolver},
        canActivate: [authGuard]
    }
];
