import {ResolveFn} from '@angular/router';
import {inject} from "@angular/core";
import {ValuesService} from "../_services/values.service";

export const storeNamesResolver: ResolveFn<boolean> = async (route, state) => {
    const valuesService = inject(ValuesService);
    if (valuesService.storeNames().length) return true;
    await valuesService.loadStoresNames();
    return valuesService.storeNames().length > 0;
};
