<mat-toolbar class="flex justify-between items-center" color="primary" >
    <div>
        <a mat-button [routerLink]="['/']" >Home</a>
    </div>

    <div>
        @if (auth.user$ | async) {
            <button mat-button [matMenuTriggerFor]="me">
                <mat-icon>arrow_drop_down</mat-icon>
                <span>Hi, {{(auth.user$ | async)?.name}}</span>
            </button>

            <mat-menu #me="matMenu">
                <a mat-menu-item routerLink="/qrph-reports">View QRPH Reports</a>
                <button mat-menu-item (click)="logout()" >Logout</button>
            </mat-menu>
        } @else {
            <app-auth-button></app-auth-button>
        }
    </div>
</mat-toolbar>
