import {Component} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {MatButtonModule} from "@angular/material/button";

@Component({
    selector: 'app-auth-button',
    standalone: true,
    imports: [MatButtonModule],
    template: `<button mat-button (click)="auth.loginWithRedirect()">Log in</button>`,
})
export class AuthButtonComponent {
    constructor(public auth: AuthService) { }
}
