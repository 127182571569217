import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideAuth0, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from "../environments/environment";
import {DATE_PIPE_DEFAULT_OPTIONS} from "@angular/common";

export const appConfig: ApplicationConfig = {
    providers: [
        provideAuth0({
            domain: environment.auth0Domain,
            clientId: environment.auth0ClientId,
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience:environment.auth0Audience
            },
            httpInterceptor: {
                allowedList: [
                    {
                        uri: 'https://delivery.lido.ph/api/*',
                        tokenOptions: {
                            authorizationParams: {
                                audience: environment.auth0Audience
                            }
                        }
                    },
                    {
                        uri: 'https://lidodelivery.com/api/*',
                        tokenOptions: {
                            authorizationParams: {
                                audience: environment.auth0Audience
                            }
                        }
                    },
                    {
                        uri: 'https://dev.lidodelivery.com/api/*',
                        tokenOptions: {
                            authorizationParams: {
                                audience: environment.auth0Audience
                            }
                        }
                    }
                ],
            },
        }),
        provideZoneChangeDetection({eventCoalescing: true}), provideRouter(routes), provideAnimationsAsync(),
        provideHttpClient(
            withInterceptorsFromDi(),
        ),
        {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
        {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {timezone: 'UTC'}},
    ]
};
